'use client'

import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useState, useEffect } from 'react'

import { useShowroomQuery } from '@/features/showroom/queries/useShowroomQuery'

import { sortedList } from 'shared/utils/sortedList'
import {
  InfiniteDecoReviewSlider,
  IntroReviewSlider,
  PortfolioSliderItem,
  VideoSliderItem,
  RtlInfiniteDecoReviewSlider,
} from './_components/BootCampSliderItem'
import Counter from './_components/NumberCounter'
import { RadioButton } from './_components/RadioButtonItem'
import VideoModal from './_components/VideoModal'
import {
  decoReview1SlideList,
  decoReview2SlideList,
  faqList,
  introReviewSlideList,
  KDT_KLASS_TAB_LIST,
  portfolioSlideList,
  firstCompanyList,
  secondCompanyList,
  toolLogoList,
  videoList,
} from './_const/index'
import styles from './Home.module.scss'
import KDTKlassCard from '@/features/klass/components'
import ViewDetailsButton from './_components/ViewDetailsButton'
import dynamic from 'next/dynamic'
import ChannelService from '@/shared/utils/channeltalk'

const ToolTip = dynamic(() => import('./_components/ToolTip'), { ssr: false })

export default function Home() {
  const channelService = ChannelService()
  const [selectedTab, setSelectedTab] = useState<string>('apply')
  const { data: showroomList } = useShowroomQuery('home', 'kdt')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState('')

  const getKlassTabList = (type: string) => {
    return showroomList
      ? selectedTab === 'all'
        ? [
            ...sortedList(showroomList[0].courses, 'apply'),
            ...sortedList(showroomList[0].courses, 'early'),
            ...sortedList(showroomList[0].courses, 'end'),
          ]
        : sortedList(showroomList[0].courses, type)
      : []
  }

  const handleKlassTabChange = (value: string) => {
    setSelectedTab(value)
  }

  const onClickVideoCard = (url: string) => {
    setVideoUrl(url)
    setIsModalOpen(true)
  }

  useEffect(() => {
    const hash = window.location.hash
    if (hash) {
      const id = hash.replace('#', '')
      moveSection(id)
    }
  }, [])

  const moveSection = (key: string) => {
    const stickyElementHeight = document.querySelector<HTMLElement>('.sticky')?.offsetHeight || 0
    const element = document.getElementById(key)
    if (!element) return
    const elementTop = element.getBoundingClientRect().top + window.scrollY
    const calTop = elementTop - stickyElementHeight

    window.scrollTo({
      top: calTop,
      behavior: 'smooth',
    })
  }

  return (
    <main className={`${styles.MinWidth}`}>
      <section className="max-w-full overflow-hidden bg-gradient-to-b from-[#FCF6F3] to-white py-14 md:py-16 lg:pb-20 lg:pt-[75px]">
        <div
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className="mx-auto"
        >
          <img
            className="mx-auto mb-3 w-[200px] lg:mb-4 lg:w-[273.42px]"
            src={'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/intro_logo.png'}
            alt="[멋쟁이사자처럼 x 고용노동부]"
          />
          <h2 className="mb-3 flex flex-col text-center text-[28px] font-bold lg:mb-[17px] lg:gap-1 lg:text-[46px]">
            <div
              className="mx-auto inline-flex h-[36px] items-center justify-center bg-gradient-to-r from-yellow-450 to-[#EC64A6] bg-clip-text lg:h-[60px]"
              style={{
                WebkitTextFillColor: 'transparent',
              }}
            >
              <span className="mr-2 hidden lg:inline-block">멋쟁이사자처럼의</span>
              <span className="mr-1.5 inline-block lg:hidden">멋사의</span> 12년 교육 노하우로
            </div>
            <div className="flex h-[36px] items-center justify-center text-[#1E1E1E] lg:h-[60px]">
              IT 취업의 꿈을 현실로!
            </div>
          </h2>
          <p className="text-center text-[15px] text-gray-500 lg:text-xl">
            교육비 전액 무료로 IT전문가로 성장할 수 있는 기회
          </p>
        </div>

        <div
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className={`${styles.intro_review_slide} mx-4 mt-[22px] md:mx-5 lg:mx-[50px] lg:mt-16`}
        >
          <IntroReviewSlider contents={introReviewSlideList} />
        </div>
      </section>

      <section
        id="bootcampList"
        className="flex max-w-full items-center justify-center overflow-hidden bg-white py-14 md:py-16 lg:pb-[100px] lg:pt-[60px]"
      >
        <div className="mx-4 w-full max-w-[1248px] lg:mx-6">
          <div
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className="mb-8 flex flex-col gap-2.5 md:gap-3 lg:mb-10 lg:gap-4"
          >
            <h4 className="text-2xl font-semibold text-[#1E1E1E] lg:text-4xl lg:font-bold">
              전액 국비지원 부트캠프
            </h4>
            <ul className="flex flex-row gap-2 lg:gap-2.5">
              {KDT_KLASS_TAB_LIST.map(({ label, type }) => (
                <li key={`부트캠프_${label}`}>
                  <RadioButton
                    label={label}
                    checked={selectedTab === type}
                    onChange={() => handleKlassTabChange(type)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div data-aos-once="true" data-aos="fade-up" data-aos-duration="1000" data-offset="5000">
            {showroomList && (
              <ul className="grid w-full grid-cols-2 gap-x-3 gap-y-7 md:grid-cols-3 md:gap-x-4 md:gap-y-8 lg:grid-cols-4 lg:gap-x-[18.67px] lg:gap-y-10">
                {getKlassTabList(selectedTab).map(course => (
                  <KDTKlassCard course={course} key={course.id} />
                ))}
              </ul>
            )}
          </div>
        </div>
      </section>

      <section className="max-w-full overflow-hidden bg-[#F6F8F9] py-14 md:py-16 lg:py-[100px]">
        <div className="mx-auto max-w-[1248px]">
          <h4
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className="mb-8 text-center text-[28px] font-bold leading-[1.32] text-[#1E1E1E] lg:mb-10 lg:text-[44px]"
          >
            왜 멋쟁이사자처럼을 <br className="block md:hidden" />
            선택해야 할까요?
          </h4>
          <ul
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className="mx-4 flex flex-col items-center justify-center gap-2.5 lg:mx-auto lg:flex-row lg:gap-16"
          >
            <li className="flex w-full flex-row items-center justify-between rounded-2xl bg-white px-6 py-4 lg:w-[200px] lg:flex-col lg:justify-center lg:gap-2.5 lg:rounded-none lg:bg-transparent lg:px-0 lg:py-0">
              <div className="flex flex-row items-center justify-center gap-1.5 lg:gap-1">
                <img
                  className="h-5 w-5 lg:h-8 lg:w-8"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_deco_black_nib.svg'
                  }
                  alt="-"
                />
                <p className="text-base font-semibold text-trueGray-800 lg:text-lg">
                  멋사 누적 수강생
                </p>
                <ToolTip message="최근 3년 멋쟁이사자처럼 누적 수강생" />
              </div>
              <div className="flex flex-col items-end gap-0.5 lg:items-center lg:gap-2.5">
                <p className="text-xl font-bold leading-none text-[#1E1E1E] lg:h-[46px] lg:text-[46px]">
                  <Counter start={0} end="15,000" duration={1500} />+
                </p>
              </div>
            </li>
            <li className="flex w-full flex-row items-center justify-between rounded-2xl bg-white px-6 py-4 lg:w-[200px] lg:flex-col lg:justify-center lg:gap-2.5 lg:rounded-none lg:bg-transparent lg:px-0 lg:py-0">
              <div className="flex flex-row items-center justify-center gap-1.5 lg:gap-1">
                <img
                  className="h-5 w-5 lg:h-8 lg:w-8"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_deco_camping.svg'
                  }
                  alt="-"
                />
                <p className="font-semibold text-trueGray-800 lg:text-lg">부트캠프 수강생</p>
                <ToolTip message="멋사 부트캠프 누적 수강생" />
              </div>
              <div className="flex flex-col items-end gap-0.5 lg:items-center lg:gap-2.5">
                <p className="text-xl font-bold leading-none text-[#1E1E1E] lg:h-[46px] lg:text-[46px]">
                  <Counter start={0} end="3,612" duration={1500} />+
                </p>
              </div>
            </li>
            <li className="flex w-full flex-row items-center justify-between rounded-2xl bg-white px-6 py-4 lg:w-[200px] lg:flex-col lg:justify-center lg:gap-2.5 lg:rounded-none lg:bg-transparent lg:px-0 lg:py-0">
              <div className="flex flex-row items-center justify-center gap-1.5 lg:gap-1">
                <img
                  className="h-5 w-5 lg:h-8 lg:w-8"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_deco_military_medal.svg'
                  }
                  alt="-"
                />
                <p className="font-semibold text-trueGray-800 lg:text-lg">수료율</p>
                <ToolTip message="23년 하반기 웹 과정 수료율" />
              </div>
              <div className="flex flex-col items-end gap-0.5 lg:items-center lg:gap-2.5">
                <p className="text-xl font-bold leading-none text-[#1E1E1E] lg:h-[46px] lg:text-[46px]">
                  <Counter start={0} end="92" duration={1500} />%
                </p>
              </div>
            </li>
          </ul>
          <ul
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className={`${styles.section3_ul_wrap} mx-4 mt-6 flex flex-col gap-4 lg:mx-6 lg:mt-10 lg:flex-row lg:gap-9`}
          >
            <li
              className={`${styles.section3_li} ${styles.section3_li_1st} flex flex-1 flex-col rounded-[20px] bg-white py-[30px] shadow-[0px_4px_54px_0px_rgba(0,0,0,0.05)] md:h-[230px] md:flex-row md:items-center md:justify-between md:py-0 lg:h-auto lg:flex-col lg:items-start lg:pb-[29.7px] lg:pt-[42px]`}
            >
              <div className="mx-7 mb-6 flex flex-col gap-2 md:mb-0 lg:mx-9 lg:mb-[27.3px] lg:gap-3">
                <p
                  className="inline-block bg-gradient-to-r from-yellow-450 to-[#EC64A6] bg-clip-text text-[15px] font-semibold lg:text-base"
                  style={{
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  실시간 온라인 부트캠프
                </p>
                <p className="text-2xl font-semibold leading-[1.33] text-[#1E1E1E] lg:text-[28px] lg:font-bold lg:leading-tight">
                  거주지역 상관없는
                  <br />
                  실시간 온라인 부트캠프
                </p>
                <p className="text-[15px] leading-[1.6] text-gray-500 md:text-base lg:leading-relaxed">
                  녹화된 강의 영상 제공이 아닌 실시간 강의로
                  <br />
                  바로 질문하고 학습 할 수 있어요!
                </p>
              </div>
              <div className="relative w-full overflow-hidden pb-[67.02%] md:w-[344px] md:pb-[230px] lg:w-full lg:pb-[67.02%]">
                <div
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-easing="ease-out"
                  data-aos-delay="400"
                  className={`${styles.pulse_live} absolute z-[1] flex items-center justify-center rounded-full bg-gradient-to-r from-yellow-450 to-[#EC64A6] text-xl font-bold text-white md:left-[20%] md:top-[25%] md:h-[39px] md:w-[92.3px] lg:left-[61.41px] lg:top-[45.75px] lg:h-[44px] lg:w-[106px] lg:text-2xl`}
                >
                  LIVE
                </div>
                <img
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-easing="ease-out"
                  data-aos-delay="1000"
                  data-offset="200"
                  className="absolute top-[4%] object-contain md:left-[66px] md:h-[204px] md:w-[307px] lg:left-[63.45px] lg:h-[234.25px] lg:w-[405.22px]"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_box1_book.png'
                  }
                  alt="-"
                />
              </div>
            </li>
            <li
              className={`${styles.section3_li} ${styles.section3_li_2rd} flex flex-1 flex-col rounded-[20px] bg-white py-[30px] shadow-[0px_4px_54px_0px_rgba(0,0,0,0.05)] md:h-[230px] md:flex-row md:items-center md:justify-between md:py-0 lg:h-auto lg:flex-col lg:items-start lg:pb-[29.7px] lg:pt-[42px]`}
            >
              <div className="mx-7 mb-6 flex flex-col gap-2 md:mb-0 lg:mx-9 lg:mb-[27.3px] lg:gap-3">
                <p
                  className="inline-block bg-gradient-to-r from-yellow-450 to-[#EC64A6] bg-clip-text text-[15px] font-semibold lg:text-base"
                  style={{
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  개인별 밀착케어
                </p>
                <p className="text-2xl font-semibold leading-[1.33] text-[#1E1E1E] lg:text-[28px] lg:font-bold lg:leading-tight">
                  학습부터 취업까지
                  <br />
                  체계적인 케어 시스템
                </p>
                <p className="text-[15px] leading-[1.6] text-gray-500 md:text-base lg:leading-relaxed">
                  비전공자도 취업할 수 있도록
                  <br />
                  개인별 체계적인 케어 시스템을 제공해요.
                </p>
              </div>
              <div className="relative w-full overflow-hidden pb-[66.86%] md:w-[344px] md:pb-[230px] lg:w-full lg:pb-[67.02%]">
                <img
                  data-aos="fade-right"
                  data-aos-once="true"
                  data-aos-easing="ease-out"
                  data-aos-delay="200"
                  className="absolute md:h-[90px] md:w-[74px] lg:h-[104px] lg:w-[82px]"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_2rd_img1.png'
                  }
                  alt="중간평가"
                />
                <img
                  data-aos="fade-right"
                  data-aos-once="true"
                  data-aos-easing="ease-out"
                  data-aos-delay="300"
                  className="absolute md:h-[90px] md:w-[74px] lg:h-[104px] lg:w-[82px]"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_2rd_img2.png'
                  }
                  alt="포트폴리오"
                />
                <img
                  data-aos="fade-right"
                  data-aos-once="true"
                  data-aos-easing="ease-out"
                  data-aos-delay="600"
                  className="absolute md:h-[90px] md:w-[74px] lg:h-[104px] lg:w-[82px]"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_2rd_img3.png'
                  }
                  alt="포지션추천"
                />
                <img
                  data-aos="fade-right"
                  data-aos-once="true"
                  data-aos-easing="ease-out"
                  data-aos-delay="700"
                  className="absolute md:h-[90px] md:w-[74px] lg:h-[104px] lg:w-[82px]"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_2rd_img4.png'
                  }
                  alt="취업지원"
                />
                <img
                  data-aos="fade-right"
                  data-aos-once="true"
                  data-aos-easing="ease-out"
                  data-aos-delay="800"
                  className="absolute md:h-[90px] md:w-[74px] lg:h-[104px] lg:w-[82px]"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_2rd_img5.png'
                  }
                  alt="학습상담"
                />
              </div>
            </li>
            <li className="flex flex-1 flex-col rounded-[20px] bg-white py-[30px] shadow-[0px_4px_54px_0px_rgba(0,0,0,0.05)] md:h-[230px] md:flex-row md:items-center md:justify-between md:py-0 lg:h-auto lg:flex-col lg:items-start lg:pb-[29.7px] lg:pt-[42px]">
              <div className="mx-7 mb-6 flex flex-col gap-2 md:mb-0 lg:mx-9 lg:mb-[27.3px] lg:gap-3">
                <p
                  className="inline-block bg-gradient-to-r from-yellow-450 to-[#EC64A6] bg-clip-text text-[15px] font-semibold lg:text-base"
                  style={{
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  최신 트렌드 커리큘럼
                </p>
                <p className="text-2xl font-semibold leading-[1.33] text-[#1E1E1E] lg:text-[28px] lg:font-bold lg:leading-tight">
                  시장 동향을 고려한
                  <br />
                  최신 트렌드 커리큘럼
                </p>
                <p className="text-[15px] leading-[1.6] text-gray-500 md:text-base lg:leading-relaxed">
                  최신 채용공고를 기반으로 현 시장에서
                  <br />
                  가장 필요로 하는 인재로 성장할 수 있어요.
                </p>
              </div>
              <div className="relative w-full overflow-hidden pb-[67.02%] md:w-[344px] md:pb-[230px] lg:w-full lg:pb-[67.02%]">
                <img
                  className="absolute left-0 top-[50%] translate-y-[-50%]"
                  src={
                    'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section3_li_3rd_bg.png'
                  }
                  alt="-"
                />

                <div className="absolute left-0 top-[50%] translate-y-[-50%] overflow-hidden">
                  <div className={`${styles.custom_flow_slider}`}>
                    <div className={`${styles.custom_flow_slider_track}`}>
                      {[...toolLogoList, ...toolLogoList].map((el, idx) => (
                        <div
                          className={`${styles.custom_flow_slider_item}`}
                          key={`infinite_${idx}`}
                        >
                          <div className="mx-auto w-full">
                            <img src={el} alt="logo" />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section className="flex max-w-full flex-col items-center overflow-hidden pt-14 md:pt-16 lg:pt-[100px]">
        <div className="mx-auto max-w-[1000px] lg:mx-6">
          <h4
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className="mb-8 text-center text-[28px] font-bold leading-[1.32] text-[#1E1E1E] lg:mb-[60px] lg:text-[44px]"
          >
            <span className="hidden text-gray-400 lg:inline">3단계 취업지원 프로그램을 통해</span>
            <span className="inline text-gray-400 lg:hidden">취업지원 프로그램을 통해 </span>
            <br />
            취업의 문을 함께 열어드려요
          </h4>

          <ul className="mx-4 flex flex-col gap-12 md:gap-[54px] lg:mx-auto lg:gap-[58px]">
            <li
              data-aos-once="true"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-offset="5000"
              className="flex flex-col-reverse items-center gap-7 md:flex-row-reverse md:gap-8 lg:flex-row lg:gap-16"
            >
              <div className="flex w-full flex-col gap-2.5 md:w-[calc(50%-16px)] lg:w-[calc(50%-32px)] lg:gap-4">
                <p className="text-base font-medium text-gray-500 lg:text-lg">
                  <span
                    className="mr-2.5 inline-block bg-gradient-to-r from-yellow-450 to-[#EC64A6] bg-clip-text font-semibold lg:font-bold"
                    style={{
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    STEP 01
                  </span>
                  자기 탐색
                </p>
                <p className="text-2xl font-semibold leading-[1.33] text-[#1E1E1E] lg:text-[32px] lg:font-bold">
                  자기 탐색 기반의
                  <br />
                  수료 후 진로 설계
                </p>
                <p className="text-base leading-relaxed text-gray-500">
                  개인 역량/경험 탐색 관련
                  <br />
                  특강 프로그램을 통해 취업 전략을 수립해요
                </p>
              </div>
              <img
                className="md:w-[calc(50%-16px)] lg:w-[calc(50%-32px)]"
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section4_img1.png'
                }
                alt="-"
              />
            </li>
            <li
              data-aos-once="true"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-offset="5000"
              className="flex flex-col-reverse items-center gap-7 md:flex-row md:gap-8 lg:flex-row-reverse lg:gap-16"
            >
              <div className="flex w-full flex-col gap-2.5 md:w-[calc(50%-16px)] lg:w-[calc(50%-32px)] lg:gap-4">
                <p className="text-base font-medium text-gray-500 lg:text-lg">
                  <span
                    className="mr-2.5 inline-block bg-gradient-to-r from-yellow-450 to-[#EC64A6] bg-clip-text font-semibold lg:font-bold"
                    style={{
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    STEP 02
                  </span>
                  입사 지원 준비
                </p>
                <p className="text-2xl font-semibold leading-[1.33] text-[#1E1E1E] lg:text-[32px] lg:font-bold">
                  이력서 샘플 및 대기업
                  <br />
                  합격 자기소개서 예시 제공
                </p>
                <p className="text-base leading-relaxed text-gray-500">
                  서류전형 특강과 다양한 샘플 자료를 활용해서
                  <br />
                  입사 지원 서류를 준비해요.
                </p>
              </div>
              <img
                className="md:w-[calc(50%-16px)] lg:w-[calc(50%-32px)]"
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section4_img2.png'
                }
                alt="-"
              />
            </li>
            <li
              data-aos-once="true"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-offset="5000"
              className="flex flex-col-reverse items-center gap-7 md:flex-row-reverse md:gap-8 lg:flex-row lg:gap-16"
            >
              <div className="flex w-full flex-col gap-2.5 md:w-[calc(50%-16px)] lg:w-[calc(50%-32px)] lg:gap-4">
                <p className="text-base font-medium text-gray-500 lg:text-lg">
                  <span
                    className="mr-2.5 inline-block bg-gradient-to-r from-yellow-450 to-[#EC64A6] bg-clip-text font-semibold lg:font-bold"
                    style={{
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    STEP 03
                  </span>
                  취업 지원 프로그램
                </p>
                <p className="text-2xl font-semibold leading-[1.33] text-[#1E1E1E] lg:text-[32px] lg:font-bold">
                  수료 후에도 6개월 간
                  <br />
                  다양한 취업 지원 프로그램 운영
                </p>
                <p className="text-base leading-relaxed text-gray-500">
                  수료 후에도 6개월 간 <br />
                  서류 피드백 및 상담 신청이 가능해요.
                </p>
              </div>
              <img
                className="md:w-[calc(50%-16px)] lg:w-[calc(50%-32px)]"
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/section4_img3.png'
                }
                alt="-"
              />
            </li>
          </ul>

          <div
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className="my-8 flex items-center justify-center lg:my-[60px]"
          >
            <ViewDetailsButton title="취업지원 프로그램 확인하기" link="/job-support" />
          </div>
        </div>
      </section>

      <section className="max-w-full overflow-hidden pb-14 md:pb-16 lg:pb-[100px]">
        <h5
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className="mb-3 text-center text-2xl font-semibold leading-[1.33] text-[#1E1E1E] lg:mb-12 lg:text-4xl lg:font-bold"
        >
          6개월 후, 내가 원하는 <br className="block md:hidden" />
          기업의 IT 인재로 거듭나세요.
        </h5>

        <div className={`${styles.company_logo_flow_slider_wrap} flex flex-col overflow-hidden`}>
          <div className={`${styles.company_logo_flow_slider}`}>
            <div className={`${styles.company_logo_flow_slider_track}`}>
              {[...firstCompanyList, ...firstCompanyList, ...firstCompanyList].map((item, idx) => (
                <div key={`${item}_${idx}`} className={styles.slider_item}>
                  <img className="h-full w-full object-contain" src={item} alt="company_logo" />
                </div>
              ))}
            </div>
          </div>

          <div
            className={`${styles.company_logo_flow_slider} ${styles.company_logo_flow_slider_second}`}
          >
            <div className={`${styles.company_logo_flow_slider_track}`}>
              {[...secondCompanyList, ...secondCompanyList, ...secondCompanyList].map(
                (item, idx) => (
                  <div key={`${item}_${idx}`} className={styles.slider_item}>
                    <img className="h-full w-full object-contain" src={item} alt="company_logo" />
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="max-w-full overflow-hidden bg-gradient-to-b from-black to-[#343434] pb-[57px] pt-14 md:pb-12 md:pt-16 lg:py-[100px]">
        <h4
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className="mb-8 flex flex-col items-center text-center lg:mb-11"
        >
          <span
            className="hidden max-w-fit bg-gradient-to-r from-yellow-450 to-[#EC64A6] bg-clip-text font-semibold lg:mb-3 lg:inline-block lg:text-lg"
            style={{
              WebkitTextFillColor: 'transparent',
            }}
          >
            실제 수강생 프로젝트
          </span>
          <span className="flex h-[37px] items-center justify-center text-[28px] font-bold text-gray-500 lg:h-[57px] lg:text-[44px]">
            현직자들도 관심가지는
          </span>
          <span className="flex h-[37px] items-center justify-center text-[28px] font-bold text-white lg:h-[57px] lg:text-[44px]">
            수강생 프로젝트
          </span>
        </h4>

        <div
          className={styles.portfolio_slide}
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
        >
          <PortfolioSliderItem contents={portfolioSlideList} />
        </div>

        <div
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className="mt-8 flex items-center justify-center lg:mt-11"
        >
          <ViewDetailsButton
            title="우수 프로젝트 자세히보기"
            link="https://docs.channel.io/likelion/ko/categories/81d76cb7-%EC%9A%B0%EC%88%98-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8"
          />
        </div>
      </section>

      <section className="max-w-full overflow-hidden bg-gradient-to-b from-[#F6F8F9] to-white py-14 md:py-16 lg:py-[100px]">
        <h4
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className="mb-8 flex flex-col text-center lg:mb-10"
        >
          <div className="flex h-9 items-center justify-center text-[28px] font-bold text-gray-400 lg:h-[57px] lg:text-[44px]">
            꿈을 이룬
            <div className="w-[162.2 px] ml-2 flex flex-row justify-end text-right">
              <Counter start={0} end="3,618" duration={1500} />
            </div>
            +명의
          </div>
          <div className="flex h-9 items-center justify-center text-[28px] font-bold text-[#1E1E1E] lg:h-[57px] lg:text-[44px]">
            수강생들의 이야기
          </div>
        </h4>

        <div className="mx-auto max-w-[1248px] lg:mx-auto lg:mb-7">
          <ul
            className="hidden flex-row gap-5 lg:mx-6 lg:flex"
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
          >
            {videoList.map(videoInfo => (
              <li
                key={videoInfo.title}
                className="relative flex cursor-pointer flex-col gap-6 overflow-hidden"
                onClick={() => onClickVideoCard(videoInfo.videoUrl)}
              >
                <div className="relative overflow-hidden rounded-[20px]">
                  <img
                    className="h-full w-full object-cover"
                    src={videoInfo.imgUrl}
                    alt={videoInfo.title}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <h6 className="whitespace-pre-wrap text-2xl font-semibold text-[#1E1E1E]">
                    {videoInfo.title}
                  </h6>
                  <p className="text-lg text-gray-500">{videoInfo.hashtag}</p>
                </div>
              </li>
            ))}
          </ul>

          <div
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className={`${styles.video_slide} lg:hidden`}
          >
            <VideoSliderItem contents={videoList} onClickVideoCard={onClickVideoCard} />
          </div>
        </div>

        <div
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className={`${styles.deco_review_slider} mt-7 hidden !opacity-[0.52] lg:mt-8 lg:block`}
        >
          <InfiniteDecoReviewSlider contents={decoReview1SlideList} />
        </div>

        <div
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className={`${styles.deco_review_slider} hidden !opacity-[0.16] lg:block`}
        >
          <RtlInfiniteDecoReviewSlider contents={decoReview2SlideList} />
        </div>

        <div className="relative z-10 mt-8 flex items-center justify-center lg:mt-[-123px]">
          <ViewDetailsButton
            title="성공 스토리 자세히보기"
            link="https://docs.channel.io/likelion/ko/categories/191b0433-%EC%84%B1%EA%B3%B5-%EC%8A%A4%ED%86%A0%EB%A6%AC"
          />
        </div>
      </section>

      <section className="max-w-full overflow-hidden bg-white py-14 md:py-16 lg:py-[100px]">
        <h5
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className="mb-8 text-center text-[28px] font-bold text-[#1E1E1E] lg:mb-[34px] lg:text-4xl"
        >
          부트캠프 지원 절차
        </h5>

        <ul
          data-aos-once="true"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-offset="5000"
          className={`${styles.apply_step_wrap} mx-4 flex max-w-[1236px] flex-col items-center justify-center gap-1.5 lg:mx-auto lg:flex-row lg:gap-0`}
        >
          <li className="flex w-full flex-row items-center justify-center gap-5 py-3 lg:w-[220px] lg:flex-col lg:gap-7">
            <div className="h-[62px] w-[62px] overflow-hidden rounded-[15.98px] shadow-[4.376px_13.129px_48.628px_-6.322px_rgba(22,52,80,0.10)] lg:h-[100px] lg:w-[100px] lg:rounded-3xl">
              <img
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/apply_step_icon_1.png'
                }
                alt="-"
              />
            </div>
            <div className="flex flex-1 flex-col items-start justify-center gap-1 lg:items-center lg:gap-3">
              <p className="text-left text-lg font-semibold text-[#1E1E1E] lg:text-center lg:text-2xl">
                <span className="inline-block lg:hidden">1.</span> 지원서 작성
              </p>
              <p className="text-left text-[15px] leading-[1.44] text-gray-500 lg:text-center lg:text-lg">
                멋사 부트캠프 지원서를 <br className="hidden lg:block" />
                작성해주세요.
              </p>
            </div>
          </li>
          <li className="hidden flex-row gap-2 lg:flex">
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
          </li>
          <li className="flex w-full flex-row items-center justify-center gap-5 py-3 lg:w-[220px] lg:flex-col lg:gap-7">
            <div className="h-[62px] w-[62px] overflow-hidden rounded-[15.98px] shadow-[4.376px_13.129px_48.628px_-6.322px_rgba(22,52,80,0.10)] lg:h-[100px] lg:w-[100px] lg:rounded-3xl">
              <img
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/apply_step_icon_2.png'
                }
                alt="-"
              />
            </div>
            <div className="flex flex-1 flex-col items-start justify-center gap-1 lg:items-center lg:gap-3">
              <p className="text-left text-lg font-semibold text-[#1E1E1E] lg:text-center lg:text-2xl">
                <span className="inline-block lg:hidden">2.</span> 지원자 평가
              </p>
              <p className="block text-left text-[15px] leading-[1.44] text-gray-500 lg:hidden lg:text-center lg:text-lg">
                작성주신 지원서를 꼼꼼히 살펴볼게요.
              </p>
              <p className="hidden text-left text-[15px] leading-[1.44] text-gray-500 lg:block lg:text-center lg:text-lg">
                평가 기준을 기반으로 <br />
                제출하신 지원서를 살펴볼게요.
              </p>
            </div>
          </li>
          <li className="hidden flex-row gap-2 lg:flex">
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
          </li>
          <li className="flex w-full flex-row items-center justify-center gap-5 py-3 lg:w-[220px] lg:flex-col lg:gap-7">
            <div className="h-[62px] w-[62px] overflow-hidden rounded-[15.98px] shadow-[4.376px_13.129px_48.628px_-6.322px_rgba(22,52,80,0.10)] lg:h-[100px] lg:w-[100px] lg:rounded-3xl">
              <img
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/apply_step_icon_3.png'
                }
                alt="-"
              />
            </div>
            <div className="flex flex-1 flex-col items-start justify-center gap-1 lg:items-center lg:gap-3">
              <p className="text-left text-lg font-semibold text-[#1E1E1E] lg:text-center lg:text-2xl">
                <span className="inline-block lg:hidden">3.</span> 합격자 발표
              </p>
              <p className="block text-left text-[15px] leading-[1.44] text-gray-500 lg:hidden lg:text-center lg:text-lg">
                합격하신 분들에게 메일을 보내드려요.
              </p>
              <p className="hidden text-left text-[15px] leading-[1.44] text-gray-500 lg:block lg:text-center lg:text-lg">
                합격하신 분들에게 <br />
                메일을 전달드려요.
              </p>
            </div>
          </li>
          <li className="hidden flex-row gap-2 lg:flex">
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
          </li>
          <li className="flex w-full flex-row items-center justify-center gap-5 py-3 lg:w-[220px] lg:flex-col lg:gap-7">
            <div className="h-[62px] w-[62px] overflow-hidden rounded-[15.98px] shadow-[4.376px_13.129px_48.628px_-6.322px_rgba(22,52,80,0.10)] lg:h-[100px] lg:w-[100px] lg:rounded-3xl">
              <img
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/apply_step_icon_4.png'
                }
                alt="-"
              />
            </div>
            <div className="flex flex-1 flex-col items-start justify-center gap-1 lg:items-center lg:gap-3">
              <p className="text-left text-lg font-semibold text-[#1E1E1E] lg:text-center lg:text-2xl">
                <span className="inline-block lg:hidden">4.</span> 고용24 수강신청
              </p>
              <p className="block text-left text-[15px] leading-[1.44] text-gray-500 lg:hidden lg:text-center lg:text-lg">
                고용24에서 수강신청을 해주세요.
              </p>
              <p className="hidden text-left text-[15px] leading-[1.44] text-gray-500 lg:block lg:text-center lg:text-lg">
                고용 24에서 <br />
                수강신청을 해주세요.
              </p>
            </div>
          </li>
          <li className="hidden flex-row gap-2 lg:flex">
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
            <span className="h-1.5 w-1.5 rounded-full bg-[#D9D9D9]"></span>
          </li>
          <li className="flex w-full flex-row items-center justify-center gap-5 py-3 lg:w-[220px] lg:flex-col lg:gap-7">
            <div className="h-[62px] w-[62px] overflow-hidden rounded-[15.98px] shadow-[4.376px_13.129px_48.628px_-6.322px_rgba(22,52,80,0.10)] lg:h-[100px] lg:w-[100px] lg:rounded-3xl">
              <img
                src={
                  'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/apply_step_icon_5.png'
                }
                alt="-"
              />
            </div>
            <div className="flex flex-1 flex-col items-start justify-center gap-1 lg:items-center lg:gap-3">
              <p className="text-left text-lg font-semibold text-[#1E1E1E] lg:text-center lg:text-2xl">
                <span className="inline-block lg:hidden">5.</span> 최종 합류
              </p>
              <p className="block text-left text-[15px] leading-[1.44] text-gray-500 lg:hidden lg:text-center lg:text-lg">
                수강등록 완료 시 부트캠프가 시작돼요.
              </p>
              <p className="hidden text-left text-[15px] leading-[1.44] text-gray-500 lg:block lg:text-center lg:text-lg">
                고용24 등록을 마무리하면 <br />
                부트캠프가 시작돼요.
              </p>
            </div>
          </li>
        </ul>
      </section>

      <section className="max-w-full overflow-hidden bg-white py-14 md:py-16 lg:py-[100px]">
        <div className="mx-auto max-w-[1248px]">
          <h5
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className="mb-8 text-center text-[28px] font-bold text-[#1E1E1E] lg:mb-10 lg:text-4xl"
          >
            자주 묻는 질문
          </h5>

          <ul
            data-aos-once="true"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-offset="5000"
            className="mx-4 mb-8 flex flex-col lg:mx-6 lg:mb-10"
          >
            {faqList.map(faq => (
              <li
                className={`${styles.faqItem} border-b border-neutral last:border-0`}
                key={faq.question}
              >
                <details className="group">
                  <summary className="cursor-pointer list-none p-4 lg:p-8">
                    <div className="flex w-full items-center justify-between gap-2.5 lg:gap-4">
                      <div>
                        <p className="text-[15px] font-semibold leading-[1.46] text-[#1E1E1E] lg:text-xl">
                          {faq.question}
                        </p>
                      </div>
                      <ChevronDownIcon className="h-5 w-5 shrink-0 text-gray-500 group-open:rotate-180 lg:h-6 lg:w-6" />
                    </div>
                  </summary>
                  <div className="border-t border-neutral bg-[#FAFAFA] p-4 lg:px-8 lg:py-5">
                    <div className="whitespace-pre-wrap text-sm leading-[1.42] text-gray-500 lg:text-base">
                      {faq.answer && <span className="max-w-full">{faq.answer}</span>}
                    </div>
                  </div>
                </details>
              </li>
            ))}
          </ul>

          <div className="mx-4 flex flex-col items-center justify-between gap-3 rounded-[20px] bg-[#FAFAFA] py-5 lg:mx-6 lg:flex-row lg:px-8">
            <p className="text-center text-base font-semibold leading-relaxed text-gray-500 lg:text-left lg:text-lg">
              아직 부트캠프에 대한 궁금증이 <br className="block lg:hidden" />
              풀리지 않으셨나요?
            </p>
            <button
              onClick={() => channelService?.show()}
              className="inline-block cursor-pointer rounded-lg border border-[#D4D4D4] bg-white px-4 py-2.5"
            >
              <span className="text-[15px] font-semibold text-trueGray-800">1:1 채팅 문의하기</span>
            </button>
          </div>
        </div>
      </section>

      <VideoModal
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        videoUrl={videoUrl}
      />
    </main>
  )
}
